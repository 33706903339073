'use client';

import { ReactNode, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import cookie from 'cookie';
import BaseList from '@/components/base/BaseList/BaseList';
import LanguageDefault from '@/assets/icons/base/LanguageDefault.svg';
import { i18n } from '../../../i18n-config';
import BasePopover from '../base/BasePopover/BasePopover';

const languageMap = {
  en: 'English',
  es: 'español',
  zh: '中文（繁體）',
  'zh-CN': '中文（简体）',
};

interface LanguageSwitcherProps {
  children?: ReactNode; // 明確設置 children 的類型
}

const LanguageSwitcher = ({ children = null }: LanguageSwitcherProps) => {
  const router = useRouter();
  const [openDrop, setOpenDrop] = useState(false);
  const pathName = usePathname();

  const redirectedPathName = (locale) => {
    if (!pathName) return '/';
    const segments = pathName.split('/');
    segments[1] = locale;
    return segments.join('/');
  };

  const handleLocaleChange = (locale) => {
    document.cookie = cookie.serialize('NEXT_LOCALE', locale, { path: '/' });
    router.push(redirectedPathName(locale));
  };

  return (
    <div className="relative flex h-full items-center">
      <BasePopover
        popoverProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        }}
        buttonClassName=" flex items-center justify-center"
        buttonChildren={
          <button
            type="button"
            onClick={() => setOpenDrop(!openDrop)}
            aria-label="language-switcher"
          >
            {children || (
              <LanguageDefault
                width={20}
                height={20}
                media="svg"
                color="#262D42"
              />
            )}
          </button>
        }
      >
        {(closePopover) => (
          <BaseList className="flex w-[152px] flex-col bg-white">
            {i18n.locales.map((locale) => (
              <BaseList.Item
                key={locale}
                onClick={() => {
                  closePopover();
                  handleLocaleChange(locale);
                }}
                className="hover:bg-primary-100"
              >
                {languageMap[locale]}
              </BaseList.Item>
            ))}
          </BaseList>
        )}
      </BasePopover>
    </div>
  );
};

export default LanguageSwitcher;
