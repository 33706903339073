import clsx from 'clsx';
import Image from 'next/image';
import React from 'react';

interface ListItemImageProps {
  className?: string;
  imageClassName?: string;
  size?: number;
  src: string;
  alt: string;
  label?: string;
}

const ListItemImage: React.FC<ListItemImageProps> = ({
  src,
  alt,
  label,
  className,
  imageClassName,
  size = 16,
}) => {
  return (
    <div className={clsx(className, 'flex items-center gap-2')}>
      <Image
        src={src}
        alt={alt}
        width={size}
        height={size}
        className={imageClassName}
      />
      {label && <span className="typo-paragraph-sm">{label}</span>}
    </div>
  );
};

export default ListItemImage;
