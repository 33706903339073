import { BaseListItem } from '@/components/base/BaseList/BaseListItem';
import Image from 'next/image';
import React from 'react';

interface BaseGridItemProps {
  src: string;
  alt: string;
  label: string;
  size?: number;
  isMultiSelected?: boolean;
  isSelected?: boolean;
  isDisabled?: boolean;
}

const BaseGridItem = ({
  src,
  alt,
  label,
  size = 64,
  isMultiSelected = false,
  isSelected = false,
  isDisabled = false,
}: BaseGridItemProps) => {
  return (
    <BaseListItem
      isBorder={false}
      isMultiSelected={isMultiSelected}
      isSelected={isSelected}
      isDisabled={isDisabled}
    >
      <div className="flex w-full flex-col items-center justify-center gap-2">
        <Image src={src} alt={alt} width={size} height={size} />
        <span className="typo-paragraph-sm">{label}</span>
      </div>
    </BaseListItem>
  );
};

export default BaseGridItem;
