import { BaseIcon } from '@/components/base/BaseIcon/BaseIcon';
import { IconName } from '@/components/base/BaseIcon/interface';
import clsx from 'clsx';
import React from 'react';

interface ListItemIconProps {
  icon: IconName;
  size?: number;
  className?: string;
  color?: string;
}

const ListItemIcon: React.FC<ListItemIconProps> = ({
  icon,
  size = 16,
  className,
  color,
}) => {
  return (
    <span className={clsx('flex items-center justify-center', className)}>
      <BaseIcon name={icon} size={size} color={color} />
    </span>
  );
};

export default ListItemIcon;
