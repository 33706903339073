import BaseGridItem from '@/components/base/BaseList/BaseGridItem';
import { BaseListItem } from '@/components/base/BaseList/BaseListItem';
import {
  ListItemIcon,
  ListItemImage,
  ListItemParagraph,
} from '@/components/base/BaseList/ListItemContent';
import clsx from 'clsx';
import React from 'react';

interface BaseListProps {
  children: React.ReactNode;
  className?: string;
}

interface BaseListComponent extends React.FC<BaseListProps> {
  Item: typeof BaseListItem;
  GridItem: typeof BaseGridItem;
  Icon: typeof ListItemIcon;
  Image: typeof ListItemImage;
  Paragraph: typeof ListItemParagraph;
}

const BaseList: BaseListComponent = ({ children, className }) => {
  return <ul className={clsx(className)}>{children}</ul>;
};

BaseList.Item = BaseListItem;
BaseList.GridItem = BaseGridItem;
BaseList.Icon = ListItemIcon;
BaseList.Image = ListItemImage;
BaseList.Paragraph = ListItemParagraph;

export default BaseList;
