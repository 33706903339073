import clsx from 'clsx';
import React from 'react';

interface ListItemParagraphProps {
  className?: string;
  title?: string;
  label?: string;
}

const ListItemParagraph: React.FC<ListItemParagraphProps> = ({
  className,
  title,
  label,
}) => {
  return (
    <div className={clsx(className, 'flex flex-col gap-[2px]')}>
      {title && <p className="typo-label-sm">{title}</p>}
      {label && <p className="typo-paragraph-xs text-gray-800">{label}</p>}
    </div>
  );
};

export default ListItemParagraph;
