import React from 'react';
import { BaseIcon } from '@/components/base/BaseIcon/BaseIcon';

interface BaseListItemProps {
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isMultiSelected?: boolean;
  isDisabled?: boolean;
  isEditMode?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  isBorder?: boolean;
  className?: string;
  'data-value'?: string;
}

export const BaseListItem: React.FC<BaseListItemProps> = ({
  children,
  onClick,
  isSelected = false,
  isMultiSelected = false,
  isDisabled = false,
  isEditMode = false,
  onEdit,
  onDelete,
  isBorder = true,
  className,
  'data-value': dataValue,
}) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);

  const baseStyle = `${isBorder ? 'border-b border-gray-300' : ''} px-2 py-[6px] relative typo-paragraph-sm w-full text-left flex items-center cursor-pointer active:bg-primary-100`;
  const hoverStyle =
    !isDisabled && isHovered
      ? 'shadow-[inset_999px_999px_0px_0px_#0008210A]'
      : '';
  const focusedStyle = isFocused ? 'border-[#3F8DFF] border-1' : '';
  const selectedStyle = isSelected ? 'bg-primary-100 hover:shadow-none' : '';
  const multiSelectedStyle = isMultiSelected
    ? 'bg-primary-100 hover:shadow-none'
    : '';
  const disabledStyle = isDisabled
    ? ' pointer-events-none hover:shadow-none opacity-70'
    : '';
  return (
    <li
      className={`${baseStyle} ${hoverStyle} ${focusedStyle} ${selectedStyle} ${multiSelectedStyle} ${disabledStyle} ${className}`}
      onClick={!isDisabled ? onClick : undefined}
      onKeyDown={(e) => {
        if (!isDisabled && e.key === 'Enter') {
          onClick?.();
        }
      }}
      onMouseEnter={() => !isDisabled && setIsHovered(true)}
      onMouseLeave={() => !isDisabled && setIsHovered(false)}
      onFocus={() => !isDisabled && setIsFocused(true)}
      onBlur={() => !isDisabled && setIsFocused(false)}
      tabIndex={isDisabled ? -1 : 0}
      role="button"
      data-value={dataValue}
    >
      {children}
      <div className="absolute right-2 flex items-center gap-2">
        {isEditMode && isHovered && !isDisabled && (
          <>
            <button
              type="button"
              aria-label="Edit"
              onClick={(e) => {
                e.stopPropagation();
                if (onEdit) onEdit();
              }}
            >
              <BaseIcon name="EditDefault" size={16} color="#262D42" />
            </button>
            <button
              type="button"
              aria-label="Delete"
              onClick={(e) => {
                e.stopPropagation();
                if (onDelete) onDelete();
              }}
            >
              <BaseIcon name="TrashDefault" size={16} color="#D6296C" />
            </button>
          </>
        )}
        {isMultiSelected && (
          <BaseIcon name="CheckDefault" size={16} color="#0029A5" />
        )}
      </div>
    </li>
  );
};

export default BaseListItem;
